import { type CountryOptionsObject } from "../../translation-keys/country-option-keys.js";

const FormCountryOptions: CountryOptionsObject = {
  "Afghanistan": "아프가니스탄",
  "Aland Islands": "올란드 제도",
  "Albania": "알바니아",
  "Algeria": "알제리",
  "American Samoa": "아메리칸 사모아",
  "Andorra": "안도라",
  "Angola": "앙골라",
  "Anguilla": "앙귈라",
  "Antarctica": "남극",
  "Antigua and Barbuda": "앤티가 바부다",
  "Argentina": "아르헨티나",
  "Armenia": "아르메니아",
  "Aruba": "아루바",
  "Australia": "호주",
  "Austria": "오스트리아",
  "Azerbaijan": "아제르바이잔",
  "Bahamas": "바하마",
  "Bahrain": "바레인",
  "Bangladesh": "방글라데시",
  "Barbados": "바베이도스",
  "Belarus": "벨라루스",
  "Belgium": "벨기에",
  "Belize": "벨리즈",
  "Benin": "베냉",
  "Bermuda": "버뮤다",
  "Bhutan": "부탄",
  "Bolivia, Plurinational State of": "볼리비아 다민족국",
  "Bonaire, Sint Eustatius and Saba": "보네르, 신트 유스타티우스, 사바",
  "Bosnia and Herzegovina": "보스니아 헤르체고비나",
  "Botswana": "보츠와나",
  "Bouvet Island": "부베 섬",
  "Brazil": "브라질",
  "British Indian Ocean Territory": "영국령 인도양 영토",
  "Brunei Darussalam": "브루나이 다루살람",
  "Bulgaria": "불가리아",
  "Burkina Faso": "부르키나 파소",
  "Burundi": "부룬디",
  "Cambodia": "캄보디아",
  "Cameroon": "카메룬",
  "Canada": "캐나다",
  "Cape Verde": "카보베르데",
  "Cayman Islands": "케이맨 제도",
  "Central African Republic": "중앙 아프리카 공화국",
  "Chad": "차드",
  "Chile": "칠레",
  "China": "중국",
  "Chinese Taipei": "타이완",
  "Christmas Island": "크리스마스 섬",
  "Cocos (Keeling) Islands": "코코스(킬링) 제도",
  "Colombia": "콜롬비아",
  "Comoros": "코모로",
  "Congo": "콩고",
  "Congo, Democratic Republic": "콩고 민주 공화국",
  "Cook Islands": "쿡 제도",
  "Costa Rica": "코스타리카",
  "Cote d'Ivoire": "코트디부아르",
  "Croatia": "크로아티아",
  "Cuba": "쿠바",
  "Curaçao": "퀴라소",
  "Cyprus": "키프로스",
  "Czech Republic": "체코 공화국",
  "Denmark": "덴마크",
  "Djibouti": "지부티",
  "Dominica": "도미니카",
  "Dominican Republic": "도미니카 공화국",
  "East Timor": "동티모르",
  "Ecuador": "에콰도르",
  "Egypt": "이집트",
  "El Salvador": "엘살바도르",
  "Equatorial Guinea": "적도 기니",
  "Eritrea": "에리트레아",
  "Estonia": "에스토니아",
  "Ethiopia": "에티오피아",
  "Falkland Islands (Malvinas)": "포클랜드 제도(말비나스)",
  "Faroe Islands": "페로 제도",
  "Fiji": "피지",
  "Finland": "핀란드",
  "France": "프랑스",
  "French Guiana": "프랑스령 기아나",
  "French Polynesia": "프랑스령 폴리네시아",
  "French Southern Territories": "프랑스 남부 지역",
  "Gabon": "가봉",
  "Gambia": "감비아",
  "Georgia": "조지아",
  "Germany": "독일",
  "Ghana": "가나",
  "Gibraltar": "지브롤터",
  "Greece": "그리스",
  "Greenland": "그린란드",
  "Grenada": "그레나다",
  "Guadeloupe": "과들루프",
  "Guam": "괌",
  "Guatemala": "과테말라",
  "Guernsey": "건지",
  "Guinea": "기니",
  "Guinea-Bissau": "기니비사우",
  "Guyana": "가이아나",
  "Haiti": "아이티",
  "Heard Island and McDonald Islands": "허드 맥도널드 제도",
  "Holy See (Vatican City State)": "교황청(바티칸 시국)",
  "Honduras": "온두라스",
  "Hong Kong": "홍콩",
  "Hungary": "헝가리",
  "Iceland": "아이슬란드",
  "India": "인도",
  "Indonesia": "인도네시아",
  "Iran, Islamic Republic of": "이란 이슬람 공화국",
  "Iraq": "이라크",
  "Ireland": "아일랜드",
  "Isle of Man": "맨 섬",
  "Israel": "이스라엘",
  "Italy": "이탈리아",
  "Jamaica": "자메이카",
  "Japan": "Japan",
  "Jersey": "저지",
  "Jordan": "요르단",
  "Kazakhstan": "카자흐스탄",
  "Kenya": "케냐",
  "Kiribati": "키리바시",
  "Korea, Democratic People's Rep": "조선민주주의 인민공화국",
  "Korea, Republic of": "대한민국",
  "Kuwait": "쿠웨이트",
  "Kyrgyzstan": "키르기스스탄",
  "Lao People's Democratic Republic": "라오스 인민 민주 공화국",
  "Latvia": "라트비아",
  "Lebanon": "레바논",
  "Lesotho": "레소토",
  "Liberia": "라이베리아",
  "Libyan Arab Jamahiriya": "리비아 아랍 자마히리야",
  "Liechtenstein": "리히텐슈타인",
  "Lithuania": "리투아니아",
  "Luxembourg": "룩셈부르크",
  "Macau": "마카오",
  "Macedonia, former Yugoslav Rep": "마케도니아, 전 유고슬라비아 공화국",
  "Madagascar": "마다가스카르",
  "Malawi": "말라위",
  "Malaysia": "말레이시아",
  "Maldives": "몰디브",
  "Mali": "말리",
  "Malta": "몰타",
  "Marshall Islands": "마셜 제도",
  "Martinique": "마르티니크",
  "Mauritania": "모리타니",
  "Mauritius": "모리셔스",
  "Mayotte": "마요트",
  "Mexico": "멕시코",
  "Micronesia, Federated States of": "미크로네시아, 연방",
  "Moldova, Republic of": "몰도바, 공화국",
  "Monaco": "모나코",
  "Mongolia": "몽골",
  "Montenegro": "몬테네그로",
  "Montserrat": "몬세라트",
  "Morocco": "모로코",
  "Mozambique": "모잠비크",
  "Myanmar": "미얀마",
  "Namibia": "나미비아",
  "Nauru": "나우루",
  "Nepal": "네팔",
  "Netherlands": "네덜란드",
  "Netherlands Antilles": "네덜란드령 안틸레스",
  "New Caledonia": "뉴칼레도니아",
  "New Zealand": "뉴질랜드",
  "Nicaragua": "니카라과",
  "Niger": "니제르",
  "Nigeria": "나이지리아",
  "Niue": "니우에",
  "Norfolk Island": "노퍽 섬",
  "Northern Mariana Islands": "북마리아나 제도",
  "Norway": "노르웨이",
  "Oman": "오만",
  "Pakistan": "파키스탄",
  "Palau": "팔라우",
  "Palestinian Territory, Occupied": "팔레스타인 영토, 점령지",
  "Panama": "파나마",
  "Papua New Guinea": "파푸아 뉴기니",
  "Paraguay": "파라과이",
  //"Peru": "페루",
  "Philippines": "필리핀",
  "Pitcairn": "핏케언",
  "Poland": "폴란드",
  "Portugal": "포르투갈",
  "Puerto Rico": "푸에르토리코",
  "Qatar": "카타르",
  "Reunion": "레위니옹",
  "Romania": "루마니아",
  "Russian Federation": "러시아 연방",
  "Rwanda": "르완다",
  "Saint Barthélemy": "생 바르텔레미",
  "Saint Kitts and Nevis": "세인트키츠네비스",
  "Saint Lucia": "세인트 루시아",
  "Saint Martin (French part)": "생마르탱(프랑스령)",
  "Saint Pierre and Miquelon": "생피에르 미클롱",
  "Saint Vincent and the Grenadines": "세인트 빈센트 그레나딘",
  "Samoa": "사모아",
  "San Marino": "산 마리노",
  "Sao Tome and Principe": "상투메프린시페",
  "Saudi Arabia": "사우디아라비아",
  "Senegal": "세네갈",
  "Serbia": "세르비아",
  "Serbia and Montenegro": "Serbia and Montenegro",
  "Seychelles": "세이셸",
  "Sierra Leone": "시에라리온",
  "Singapore": "싱가포르",
  "Sint Maarten (Dutch part)": "신트마르텐(네덜란드령)",
  "Slovakia": "슬로바키아",
  "Slovenia": "슬로베니아",
  "Solomon Islands": "솔로몬 제도",
  "Somalia": "소말리아",
  "South Africa": "남아프리카",
  "South Georgia/South Sandwich": "사우스 조지아/사우스 샌드위치 제도",
  "South Sudan": "남수단",
  "Spain": "스페인",
  "Sri Lanka": "스리랑카",
  "St Helena, Ascension, Tristan": "세인트헬레나 어센션 트리스탄다쿠냐",
  "Sudan": "수단",
  "Suriname": "수리남",
  "Svalbard and Jan Mayen": "스발바르 얀마옌 제도",
  "Swaziland": "스와질란드",
  "Sweden": "스웨덴",
  "Switzerland": "스위스",
  "Syrian Arab Republic": "시리아 아랍 공화국",
  "Tajikistan": "타지키스탄",
  "Tanzania, United Republic of": "탄자니아, 연합 공화국",
  "Thailand": "태국",
  "Timor-Leste": "동티모르",
  "Togo": "토고",
  "Tokelau": "토켈라우",
  "Tonga": "통가",
  "Trinidad and Tobago": "트리니다드토바고",
  "Tunisia": "튀니지",
  "Turkey": "터키",
  "Turkmenistan": "투르크메니스탄",
  "Turks and Caicos Islands": "터크스케이커스 제도",
  "Tuvalu": "투발루",
  "Uganda": "우간다",
  "Ukraine": "우크라이나",
  "United Arab Emirates": "아랍 에미리트",
  "United Kingdom": "United Kingdom",
  "United States": "United States",
  "Uruguay": "우루과이",
  "US Minor Outlying Islands": "미국령 군소 제도",
  "Uzbekistan": "우즈베키스탄",
  "Vanuatu": "바누아투",
  "Venezuela, Bolivarian Republic of": "베네수엘라, 볼리바리아 공화국",
  "Viet Nam": "베트남",
  "Virgin Islands (U.S.)": "버진 제도(미국)",
  "Virgin Islands, British": "버진 제도(영국)",
  "Wallis and Futuna": "왈리스 푸투나",
  "Western Sahara": "서부 사하라",
  "Yemen": "예멘",
  "Zambia": "잠비아",
  "Zimbabwe": "짐바브웨",
};

export default FormCountryOptions;